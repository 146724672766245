import React, { useState, useEffect } from "react";
import Slider from "react-slick"; // Import react-slick
import "./HelpWithGrocery.scss";
import HelpWithGroce from "../../apis/help-with-grocery";

const HelpWithGrocery = () => {
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 991);

  // Update the state based on the window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 1300);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const slickSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    centerMode: false,
    focusOnSelect: true,
    arrows: false,
    dots: false,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <section className="help-with-groc">
      <div className="container">
        <div className="variety-plans__header no-pad">
          <p>Help With Your Groceries, Certain Utility Bills, & More</p>
          <p>If you’re eligible, a Medicare Advantage plan can be used for:</p>
        </div>

        {isMobileView ? (
          <Slider {...slickSettings} className="help-with-groc__slider">
            {HelpWithGroce?.map((data) => (
              <div className="help-with-groc__desk-card" key={data?.id}>
                <div className="border-top"></div>

                <figure>
                  <img src={data?.image} alt="" />
                </figure>
                <div
                  className="p"
                  dangerouslySetInnerHTML={{ __html: data?.text }}
                />
              </div>
            ))}
          </Slider>
        ) : (
          <div className="help-with-groc__desk">
            {HelpWithGroce?.map((data) => (
              <div className="help-with-groc__desk-card" key={data?.id}>
                <div className="border-top"></div>
                <figure>
                  <img src={data?.image} alt="" />
                </figure>
                <div
                  className="p"
                  dangerouslySetInnerHTML={{ __html: data?.text }}
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default HelpWithGrocery;
