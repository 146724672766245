import Aetna from "../images/aetna.png";
import Anthem from "../images/anthem.png";
import Cigna from "../images/cigna.png";
import Humana from "../images/humana.png";
import UnitedHealthCare from "../images/united-healthcare.png";
import WellCare from "../images/wellcare.png";

const SvgData = [
  {
    id: 1,
    image: Humana,
  },
  {
    id: 2,
    image: UnitedHealthCare,
  },
  {
    id: 3,
    image: Cigna,
  },
  {
    id: 4,
    image: WellCare,
  },
  {
    id: 5,
    image: Anthem,
  },
  {
    id: 6,
    image: Aetna,
  },
];

export default SvgData;
