import React, { useState, useEffect, useRef } from "react";
import "./Faq.scss";
import AccordionState from "../../images/accordion-state.svg";
import accordionData from "../../apis/accordion-data";
const Faq = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const contentRefs = useRef([]);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  useEffect(() => {
    contentRefs.current.forEach((content, i) => {
      if (content) {
        if (i === activeIndex) {
          content.style.maxHeight = `${content.scrollHeight}px`; // Expand to full height
        } else {
          content.style.maxHeight = "0"; // Collapse smoothly
        }
      }
    });
  }, [activeIndex]);

  return (
    <section className="faq">
      <div className="container">
        <div className="faq__header text-with-border">
          <div className="text-with-border__content">
            <h1 className="text-with-border__title">
              frequently asked questions
            </h1>
            <p className="text-with-border__subtitle">
              Do you need additional information? You can find it here.
            </p>
          </div>
        </div>

        <div className="faq__section">
          {accordionData?.map((item, index) => (
            <div
              className={`accordion-item ${
                activeIndex === index ? "active" : ""
              }`}
              key={index}
            >
              <div
                className="accordion-label"
                onClick={() => toggleAccordion(index)}
              >
                <span className="accordion-question">
                  <span className="number">0{index + 1}</span>&nbsp;
                  <p>{item?.question}</p>
                </span>
                <span className="icon">
                  <div className="plus-icon">
                    <figure>
                      <img src={AccordionState} alt="" />
                    </figure>
                  </div>
                </span>
              </div>
              <div
                className="accordion-content"
                ref={(el) => (contentRefs.current[index] = el)}
                style={{
                  overflow: "hidden",
                  transition: "max-height 0.5s ease",
                }}
              >
                <div className="accordion-flex">
                  <p>{item?.answer}</p>
                  <figure>
                    <img src={item?.image} alt="" />
                  </figure>
                </div>
                <br />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Faq;
