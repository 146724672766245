import MedsBottle from "../images/meds.svg";
import Fruits from "../images/fruits.svg";
import Dairy from "../images/dairy.svg";
import Bills from "../images/bill.svg";
import Dental from "../images/dental.svg";
import Diabetic from "../images/diabetic-presc.svg";

const HelpWithGroce = [
  {
    id: 1,
    image: MedsBottle,
    text: "Over-The-Counter <br/> Health Products",
  },
  {
    id: 2,
    image: Fruits,
    text: "Fresh <br/> Produce",
  },
  {
    id: 3,
    image: Dairy,
    text: "Healthy <br/> Food",
  },
  {
    id: 4,
    image: Bills,
    text: "Utility <br/> Bills",
  },
  {
    id: 5,
    image: Dental,
    text: "Dental <br/> Benefits",
  },
  {
    id: 6,
    image: Diabetic,
    text: "Diabetic Prescription <br/> Benefits",
  },
];

export default HelpWithGroce;
