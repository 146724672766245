import React from "react";
import "./HereToHelp.scss";
import DeliveryBag from "../../images/delivering-bag.png";
import NeatPicking from "../../images/neat-picking.png";
import BlueTick from "../../images/blue-tick-list.svg";
const HereToHelp = () => {
  return (
    <section className="here-to-help">
      <div className="container">
        <div className="wrapper">
          <div className="here-to-help__section-first text-with-border">
            <div className="text-with-border__content">
              <h1 className="text-with-border__title">We’re Here To Help</h1>
              <p className="text-with-border__subtitle">
                We aim to help you get the most out of your Medicare Advantage
                plan
              </p>
            </div>

            <div className="emt-border"></div>
            <h2>
              Dental, Vision, healthy Groceries, and More—Find a Plan That
              Offers Additional Benefits for $0 Monthly Plan Premium!
            </h2>

            <div className="section-first-details">
              <figure className="neat-pick">
                <img src={NeatPicking} alt="" />
                <span>$0 Monthly Premium</span>
              </figure>

              <div className="texts">
                <p>
                  Our commitment to providing affordable healthcare solutions
                  allows you to focus on what matters most – your health and
                  well-being.
                </p>

                <ul>
                  <li>
                    <figure>
                      <img src={BlueTick} alt="" />
                    </figure>
                    $0 Monthly Premium
                  </li>
                  <li>
                    <figure>
                      <img src={BlueTick} alt="" />
                    </figure>
                    Access the plan benefits you qualify for
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="here-to-help__section-second">
            <figure className="delivery-bag">
              <img src={DeliveryBag} alt="" />
              <span>Guiding You To The Right Plan</span>
            </figure>
            <div className="aside-details">
              <p>
                Our friendly, licensed insurance agents provide you with
                assistance and support throughout.
              </p>
              <ul>
                <li>
                  <figure>
                    <img src={BlueTick} alt="" />
                  </figure>
                  Thorough review of your eligibility
                </li>
                <li>
                  <figure>
                    <img src={BlueTick} alt="" />
                  </figure>
                  Providing guidance in finding the right plan for you
                </li>
                <li>
                  <figure>
                    <img src={BlueTick} alt="" />
                  </figure>
                  Telephonic enrollment process
                </li>
              </ul>
              <p>
                We understand that navigating Medicare Advantage plans can be
                overwhelming. That’s why we're here to guide you every step of
                the way.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default HereToHelp;
