import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./WhatPeopleAreSaying.scss";
import StarRating from "../StarRating/StarRating";
import DoubleQuote from "../../images/quote.svg";
import VerifyTick from "../../images/blue-tick.svg";
import Reviews from "../../apis/what-peopls-says-data";

const WhatPeopleAreSaying = () => {
  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    customPaging: function (i) {
      return <div className="dot"></div>;
    },
    dotsClass: "slick-dots slick-thumb",
    responsive: [
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1.06,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="what-people-says">
      <div className="container">
        <div className="what-people-says__header text-with-border">
          <div className="text-with-border__content">
            <h1 className="text-with-border__title">
              See What People Are Saying
            </h1>
            <p className="text-with-border__subtitle">
              Real reviews from our valued members.
            </p>
          </div>
        </div>

        <Slider {...sliderSettings} className="what-people-says__slider">
          {Reviews?.map((reviews) => (
            <div className="what-people-says__review-card" key={reviews?.id}>
              <h3>{reviews?.title}</h3>
              <div className="quoted-text">
                <figure>
                  <img src={DoubleQuote} alt="" />
                </figure>
                {reviews?.text}
              </div>
              <div className="reviewer-details">
                <div className="star-rating">
                  <StarRating
                    rating={reviews?.rating}
                    totalStars={5}
                    width={16}
                    height={16}
                  />
                </div>
                <div className="review-name-flex">
                  <span className="name">{reviews?.name}</span>
                  <div className="border-vr"></div>
                  <figure>
                    <img src={VerifyTick} alt="" />
                  </figure>
                  <span className="verified">Verified customer</span>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default WhatPeopleAreSaying;
