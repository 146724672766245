import React from "react";
import "./AnnouncementBar.scss";
import CallIcon from "../../images/call-icon.svg";

const AnnouncementBar = () => {
  return (
    <section className="">
      <div className="announcement_bar">
        <span>Call a licensed insurance agent</span>
        <figure>
          <img src={CallIcon} alt="" />
        </figure>
        <span className="announcement_bar-number">
          <a href="tel:+8884394708">(888) 439-4708</a>
        </span>
      </div>
    </section>
  );
};

export default AnnouncementBar;
