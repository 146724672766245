import React from "react";
import "./SeeIfQualify.scss";

const SeeIfQualify = () => {
  return (
    <section className="see-if-qualify">
      <div className="container">
        <div className="see-if-qualify__wrap">
          <div>
            <p>
              Qualifying Americans are receiving benefits that help pay for
              healthy groceries with a Medicare Advantage plan!
            </p>
          </div>
          <div className="second-text">
            <p>
              In 2024, more than half <span>&nbsp;&nbsp;54%)&nbsp;&nbsp;</span>{" "}
              of eligible Medicare beneficiaries{" "}
              <span>&nbsp;&nbsp;32.8 million people&nbsp;&nbsp;</span> are
              enrolled into a Medicare Advantage plan!*
            </p>
          </div>
        </div>

        <div className="see-if-qualify__button">
          <button>See if you qualify! 👇</button>
        </div>
      </div>
    </section>
  );
};

export default SeeIfQualify;
