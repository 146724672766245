import React from "react";
import Clock from "../../images/header-clock.svg";
import SvgData from "../../apis/company-logos";
import "./FooterComp.scss";
const FooterComp = () => {
  return (
    <div className="footer-comp">
      <div className="container">
        <div className="footer-comp__company">
          <div className="footer-comp__company-flex">
            <div className="footer-comp__company-flex-offered">
              <h2>Medicare Advantage plans offered by</h2>
              <div className="footer-comp__company-flex-offered-timing">
                <figure>
                  <img src={Clock} alt="" />
                </figure>
                <div className="timing">
                  <p>Monday - Friday 8AM - 6PM</p>
                  <p>Central Time TTY:711.</p>
                </div>
              </div>
            </div>
            <div className="footer-comp__company-svgs">
              <div className="company-card-1">
                {SvgData?.map((data) => (
                  <div key={data?.id}>
                    <img src={data?.image} alt="" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterComp;
