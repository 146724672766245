import EyedCC from "../images/eyed-cc.webp";
import ManCC from "../images/man-watching-cc.webp";
import Market from "../images/grandma-shopping.webp";

const accordionData = [
  {
    question: "What is the Spending Allowance Benefit and how can it help me?",
    image: Market,
    answer:
      "The Spending Allowance Benefit included on a Medicare Advantage plan provides a monthly or quarterly allowance for Rent/Mortgage Payments, Over-The-Counter Health Products, Fresh Produce, Healthy Food, and/or Utility Bills. This benefit can help you save money, making it easier to maintain a balanced life without straining your budget.",
  },
  {
    question: "What does this cost?",
    image: EyedCC,
    answer:
      "There is no additional cost for enrolling and there is no obligation to enroll.",
  },
  {
    question: "How do I know if I qualify?",
    image: ManCC,
    answer:
      "Eligibility depends on your specific circumstances, including, but not limited to, your income.",
  },
];

export default accordionData;
