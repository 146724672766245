import React from "react";
import "./VarietyPlans.scss";
import SvgData from "../../apis/company-logos";
const VarietyPlans = () => {
  return (
    <section className="variety-plans">
      <div className="container">
        <div className="variety-plans__header">
          <p>Pick from a variety of plans offered by trusted providers like</p>
        </div>

        <div className="variety-plans__company">
          <div className="company-card">
            {SvgData?.map((data) => (
              <div className="company-fig" key={data?.id}>
                <figure>
                  <img src={data?.image} alt="" />
                </figure>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default VarietyPlans;
