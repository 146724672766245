import React from "react";
import "./ThreeCards.scss";

import HandPeople from "../../images/hand-people.svg";
import FiveStarHand from "../../images/five-star-hand.svg";
import CustomerCare from "../../images/customer-care.svg";

const ThreeCards = () => {
  return (
    <section className="three-cards">
      <div className="container">
        <div className="three-cards__flex">
          <div className="three-cards__flex-card">
            <figure>
              <img src={HandPeople} alt="" />
            </figure>

            <div className="texts">
              <h3>100% Free of Charge Services, no obligation</h3>
              <p>
                Our services are always 100% Free of charges with no obligation
                for you to enroll.
              </p>
            </div>
          </div>
          <div className="three-cards__flex-card">
            <figure>
              <img src={FiveStarHand} alt="" />
            </figure>

            <div className="texts">
              <h3>Excellent Customer Satisfaction</h3>
              <p>
                Our customers consistently rate us highly for our exceptional
                service and personalized support.
              </p>
            </div>
          </div>
          <div className="three-cards__flex-card">
            <figure>
              <img src={CustomerCare} alt="" />
            </figure>

            <div className="texts">
              <h3>Licensed Insurance Agent</h3>
              <p>
                Our knowledgeable, licensed insurance agents are ready to guide
                you through the enrollment process.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ThreeCards;
