import HandShield from "../images/hand-shield.svg";
import TaskNote from "../images/task-note-2.svg";
import CustomerCare from "../images/customer-care-2.svg";
import FlagUSA from "../images/usa-flag.svg";

const ChoosUsData = [
  {
    id: 1,
    header: "Top Insurance Carriers",
    text: "Plans offered by trusted providers like Humana, UnitedHealthcare®, Anthem Blue Cross, WellCare, Zing, HighMark, Kaiser, and HCSC",
    image: HandShield,
  },
  {
    id: 2,
    header: "Easy Enrollment Process",
    text: "A simple, three-step process to help you find and enroll in the right plan for your needs - 100% Over the Phone.",
    image: TaskNote,
  },
  {
    id: 3,
    header: "Here to Help You All Year Long",
    text: "Our dedicated team is committed to providing ongoing support whenever you need assistance.",
    image: CustomerCare,
  },
  {
    id: 4,
    header: "Trusted Nationwide",
    text: "With a proven track record of satisfaction, people across the country rely on our services.",
    image: FlagUSA,
  },
];

export default ChoosUsData;
