import React from "react";
import "./DiscoverEligible.scss";
import OldWomanInMarket from "../../images/old-woman-shopping.png";
import VerifyTick from "../../images/blue-tick.svg";
// import YelloStar from "../../images/stars.svg";
import ReviewerOne from "../../images/reivewer-one.png";

import StrawberryCup from "../../images/old-woman-holding-strawberry.png";
import Salmon from "../../images/salmon.png";
import ReviewInMarket from "../../images/old-woman-in-market.png";
import StarRating from "../StarRating/StarRating";
import CallIcon from "../../images/white-call.svg";
import WhiteCall from "../../images/call-notification.svg";
const DiscoverEligible = () => {
  return (
    <section className="discover-elig">
      <div className="container">
        <div className="discover-elig__first">
          <figure className="old-market">
            <img src={OldWomanInMarket} alt="" />
          </figure>
          <div className="discover-elig__first-details">
            <h2>Discover Your Eligibility, Plans And Benefits</h2>
            <p>
              Understanding your Medicare Advantage plan options and eligibility
              may seem complex, but we're here to make it easy.
            </p>
            <p className="mt">
              Call now to connect with a licensed insurance agent and discover
              the right plan for you!
            </p>
          </div>
          <div className="review_one">
            <div className="review-wrap">
              <StarRating rating={5} totalStars={5} width={16} height={16} />
              <div className="text">
                <p className="text-p">Many Happy Customers</p>
              </div>
            </div>

            <div className="img">
              <figure>
                <img src={ReviewerOne} alt="" />
              </figure>
            </div>
          </div>
        </div>

        <div className="discover-elig__second">
          <figure className="one">
            <img src={StrawberryCup} alt="" />
          </figure>

          <div className="discover-elig__second-review">
            <div className="user">
              <figure className="up">
                <img src={ReviewInMarket} alt="" />
              </figure>
              <div className="user-details">
                <div>
                  <span className="user-name">Edna Moore</span>
                  <figure className="blue-tick">
                    <img src={VerifyTick} alt="" />
                  </figure>
                  <span className="verified">Verified customer</span>
                </div>
                <StarRating rating={5} totalStars={5} width={16} height={16} />
              </div>
            </div>

            <div className="review-text">
              “So far so good, I like my plan. I can go to the grocery store and
              buy me some food.”
            </div>
            <div className="review-link">
              <a href="tel:+8884394708">
                <figure>
                  <img src={CallIcon} alt="" />
                </figure>
                <span>Tap Here To Call Now</span>
              </a>
            </div>

            <div className="mobile-details">
              <div className="number">
                <figure>
                  <img src={WhiteCall} alt="" />
                </figure>
                <span>(888) 439-4708</span>
              </div>
              <div className="details">
                <p>M-F 8A-6P CT. TTY 711.</p>
                <p>Call a licensed insurance agent</p>
              </div>
            </div>
          </div>

          <figure className="two">
            <img src={Salmon} alt="" />
          </figure>
        </div>
      </div>
    </section>
  );
};

export default DiscoverEligible;
