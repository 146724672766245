import React from "react";
import "./Footer.scss";
import Logo from "../../images/logo.svg";
import FooterComp from "../FooterComp/FooterComp";

const Footer = () => {
  return (
    <div>
      <FooterComp />
      <section className="footer">
        <div className="footer__logo">
          <div className="footer__logo-main">
            <a href="/">
              <figure>
                <img src={Logo} alt="" />
              </figure>
              <div>
                <h1>
                  American <br /> Extra Help
                </h1>
                <p>Non-Government Entity</p>
              </div>
            </a>
          </div>
          <div className="footer__logo-company">
            <p>Connect with a Licensed Insurance Agent</p>
            <p>(844) 571-7265</p>
            <p>TTY Users Dial 711</p>
          </div>
        </div>
        <div className="container">
          <div className="footer__desc">
            <div className="footer__desc-one">
              <p>
                The following disclaimer applies to our national offering: We do
                not offer every plan available in your area. Currently we
                represent 5 organizations which offer 2082 products in your
                area. Please contact Medicare.Gov , 1-800-MEDICARE, or your
                local State Health Insurance Program (SHIP)to get information on
                all of your options.
              </p>
              <p>
                To request plan information without providing personal
                information, please call to speak with a licensed insurance
                agent at the phone number. We are not affiliated with any plan
                or endorsed by any government entity or agency. This site is
                operated in partnership with Health is Wealth Marketing LLC, a
                licensed insurance agency.
              </p>
            </div>
            <div className="footer__desc-other">
              <p>
                Health is Wealth Marketing LLC represents plans that are insured
                or covered by a Medicare Advantage PDP, HMO, PPO, or PFFS
                organization with a Medicare Contract and/or a Medicare-approved
                Part D Sponsor. Enrollment in plans depends on contract renewal.
                Not all plans offer all of these benefits. Benefits may vary by
                carrier and location. $0 premium plans are not available in all
                areas. Enrollees must continue to pay their Medicare Part B
                Premium. Availability varies by carrier and location.
                Deductibles, copay, and coinsurance may apply.
              </p>
              <p>
                Members may receive a monthly or quarterly allowance in the form
                of a benefits prepaid card to pay for a wide range of approved
                [groceries] [and] [utilities]. Unused amounts will expire at the
                end of the month or quarter
              </p>
              <p>
                Allowance amounts cannot be combined with other benefit
                allowances. Limitations and restrictions may apply.
              </p>
              <p>
                Products and services are provided exclusively by our partners,
                but not all offer the same plans or options. Descriptions are
                for informational purposes only and subject to change. We
                encourage you to shop around and explore all of your options.
              </p>
              <p>MULTIPLAN_HIWMLLCOCA1LP22025_M</p>
            </div>
          </div>
        </div>
      </section>
      <div className="copy-right">
        <span>Health is Wealth Marketing LLC</span>
        <div className="vr mob-hide"></div>
        <div className="mob-flex">
          <span>
            <a
              href="https://grocerybenefits.com/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>
          </span>
          <div className="vr"></div>
          <span>
            <a
              href="https://grocerybenefits.com/terms-and-conditions"
              target="_blank"
              rel="noreferrer"
            >
              Terms & Conditions
            </a>
          </span>
        </div>
      </div>
    </div>
  );
};
export default Footer;
