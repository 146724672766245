import React from "react";
import Banner from "../../Components/Banner/Banner";
import VarietyPlans from "../../Components/VarietyPlans/VarietyPlans";
import SpendingAllowance from "../../Components/SpendingAllowance/SpendingAllowance";
import FourPoints from "../../Components/FourPoints/FourPoints";
import SeeIfQualify from "../../Components/SeeIfQualify/SeeIfQualify";
import DiscoverEligible from "../../Components/DiscoverEligible/DiscoverEligible";
import HelpWithGrocery from "../../Components/HelpWithGrocery/HelpWithGrocery";
import HereToHelp from "../../Components/HereToHelp/HereToHelp";
import ThreeCards from "../../Components/ThreeCards/ThreeCards";
import ChooseUs from "../../Components/ChooseUs/ChooseUs";
import WhatPeopleAreSaying from "../../Components/WhatPeopleAreSaying/WhatPeopleAreSaying";
import Faq from "../../Components/Faq/Faq";
import DontDelay from "../../Components/DontDelay/DontDelay";
const Home = () => {
  return (
    <section>
      <Banner />
      <VarietyPlans />
      <SpendingAllowance />
      <FourPoints />
      <SeeIfQualify />
      <DiscoverEligible />
      <HelpWithGrocery />
      <HereToHelp />
      <ThreeCards />
      <ChooseUs />
      <WhatPeopleAreSaying />
      <Faq />
      <DontDelay />
    </section>
  );
};

export default Home;
