import React from "react";
import YellowStar from "../../images/stars.svg";
import GreyStar from "../../images/grey-star.svg";

const StarRating = ({ rating, totalStars = 5, width, height }) => {
  const stars = [];

  for (let i = 0; i < totalStars; i++) {
    stars.push(
      <figure key={i}>
        <img
          src={i < rating ? YellowStar : GreyStar}
          alt="Star"
          width={width}
          height={height}
        />
      </figure>
    );
  }

  return <div className="small-flex">{stars}</div>;
};

export default StarRating;
