const Reviews = [
  {
    id: 1,
    title: "Best grocery benefits allowance platform",
    text: "“The customer service is top of the line, the agent are very thorough and informative explaining everything and making buying insurance very clear , transparent, and seamless! I would give more than 5 stars if I could. 10/10 Definitely recommend!”",
    name: "Edward Plaisance",
    rating: 5,
  },
  {
    id: 2,
    title: "100% Fresh FOOD",
    text: "“Excellent service and professionalism! The agent I worked with was knowledgeable and took the time to really make sure I understood my new Medicare plan. She was able to find me a great deal with benefits that I didn’t even know I qualified for! I look forward to working with her and this agency in the future.”",
    name: "Megan Shelton",
    rating: 5,
  },
  {
    id: 3,
    title: "Amazing quality food",
    text: "Very professional! These people are great at what they do, and very thorough. They helped me save money, find extra benefits, and keep my doctors all with a smile! I am very thankful that I discovered this company for my needs! You have a client for life! Thank you!",
    name: "Steve Douglas",
    rating: 5,
  },
];

export default Reviews;
